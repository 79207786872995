<template>
	<div class="company-overview">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/zionesport-factory.png">	
			<h1 class="title" v-markdown:title>
				La *primera* plataforma *para ligas* deportivas
			</h1>
			<p class="view-header__description">
				zione.sport es la plataforma para la administración de torneos, copas y eventos deportivos de cualquier tamaño y deporte.
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";

	export default {
		name: "Company",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.company
			}
		}
	}
</script>