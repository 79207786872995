<template>
	<div class="about">
		<div class="view-header">
			<logo-zionesport class="view-header-logo" v-if="!$responsive.mobile"/>
			<zionesport-icon class="view-header-icon" v-else/>
			<h1 class="title" v-if="!$responsive.mobile">
				Acerca de nosotros
			</h1>
			<p class="view-header__description">
				Somos buenos porque somos los mejores
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";
	import logoZionesport from "@/components/LogoZionesportH.vue";
	import zionesportIcon from "@/components/ZionesportIcon.vue";

	export default {
		name: "About",
		components: {
			sectionHandler,
			logoZionesport,
			zionesportIcon
		},
		data() {
			return {
				sections: content.about
			}
		}
	}
</script>

<style scoped>
.view-header-logo {
	color: #444;
	display: inline-block;
	width: 300px;
	margin: 1.25rem 0 2rem;
}

.view-header-icon {
	width: 33vw;
	margin: 1rem 0 0;
}
</style>