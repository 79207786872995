<template>
	<div class="tech">
		<div class="view-header">
			<img class="view-header__image" src="@/assets/foo-joinus-3.png">
			<h1 class="title">
				<span class="text--highlighted">Únete</span> a Nosotros
			</h1>
			<p class="view-header__description">
				En zione.sport contratamos a las personas más curiosas y apasionadas, aquellas que proponen nuevas maneras de hacer las cosas.
			</p>
		</div>
		<!-- content -->
		<section-handler :sections="sections"></section-handler>
	</div>
</template>

<script>
	import content from "@/content.json";
	import sectionHandler from "@/components/SectionHandler";

	export default {
		name: "JoinUs",
		components: {
			sectionHandler
		},
		data() {
			return {
				sections: content.joinUs
			}
		}
	}
</script>